/* eslint-disable no-console */
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';

import { NBXStorage } from '@nbx/frontend-helpers/storage';
import isMobileDevice from '../helpers/isMobileDevice';
import { createLogger } from '../helpers/Logger';
import importFirebase from './firebase-async';
import NBXUsers from './users.service';

let firebaseConfig = {};
let appInstance = null;
let importedFirebaseAuth = null;
let vippsOpenIDConnectProvider = null;
let googleOpenIDConnectProvider = null;
let appleOpenIDConnectProvider = null;

const log = createLogger('[firebase]');
const isAndroidBrowser = /(android)/i.test(navigator.userAgent);
const VIPPS_SCOPES = 'openid api_version_2 address birthDate name phoneNumber';

const Firebase = {
  setConfig: config => {
    if (isAndroidBrowser) {
      config.authDomain = config.authDomain.replace('app', 'auth');
    }
    return (firebaseConfig = config);
  },

  // https://firebase.google.com/docs/reference/js/app.md#initializeapp
  initializeApp: async config => {
    if (importedFirebaseAuth) return importedFirebaseAuth;

    const [firebaseApp, firebaseAuth] = await importFirebase();
    appInstance = firebaseApp.initializeApp(config);
    // See https://github.com/firebase/firebase-js-sdk/issues/5019
    if (window.PLATFORM !== 'web') {
      firebaseAuth.initializeAuth(appInstance, {
        persistence: firebaseAuth.indexedDBLocalPersistence
      });
    }

    vippsOpenIDConnectProvider = new firebaseAuth.OAuthProvider('oidc.vipps');
    vippsOpenIDConnectProvider.addScope(VIPPS_SCOPES);

    googleOpenIDConnectProvider = new firebaseAuth.OAuthProvider('google.com');

    appleOpenIDConnectProvider = new firebaseAuth.OAuthProvider('apple.com');

    importedFirebaseAuth = firebaseAuth;
    return importedFirebaseAuth;
  },

  importAuth: async () => {
    return Firebase.initializeApp(firebaseConfig);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#getauth
  auth: () => Firebase.importAuth().then(firebaseAuth => firebaseAuth.getAuth(appInstance)),

  // https://firebase.google.com/docs/reference/js/auth.auth.md#auth_interface
  user: async () => {
    if (Capacitor.isNativePlatform()) {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native user()');
        return nativeUser.user;
      }
    }
    return Firebase.auth().then(auth => auth.currentUser);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#getidtoken
  getIdTokenFromNative: async forceRefresh => {
    if (Capacitor.isNativePlatform()) {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        return FirebaseAuthentication.getIdToken({ forceRefresh }).then(token => token.token);
      }
    }

    return Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.getIdToken(user, forceRefresh);
    });
  },

  getIdToken: async forceRefresh => {
    return Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.getIdToken(user, forceRefresh);
    });
  },

  // https://firebase.google.com/docs/reference/js/auth.md#createuserwithemailandpassword
  signUp: (email, password) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.createUserWithEmailAndPassword(auth, email, password);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#getadditionaluserinfo
  getAdditionalUserInfo: userCredential =>
    Firebase.importAuth().then(firebaseAuth => {
      return firebaseAuth.getAdditionalUserInfo(userCredential);
    }),

  onAuthStateChanged: async (nextOrObserver, error) => {
    const firebaseAuth = await Firebase.importAuth();
    const auth = await firebaseAuth.getAuth();
    return firebaseAuth.onAuthStateChanged(auth, nextOrObserver, error);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#signinwithemailandpassword
  login: (email, password) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      console.log('firebase.service.login, firebaseAuth.signInWithEmailAndPassword');
      return firebaseAuth.signInWithEmailAndPassword(auth, email, password);
    }),

  reauthenticateWithPassword: (email, password) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const credential = firebaseAuth.EmailAuthProvider.credential(email, password);
      const user = await Firebase.user();
      return firebaseAuth.reauthenticateWithCredential(user, credential);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#signinwithpopup
  // https://firebase.google.com/docs/reference/js/auth.md#signinwithredirect
  signInWithIDP: async (auth, provider) => {
    const firebaseAuth = await Firebase.importAuth();
    return Firebase.isRedirectFlow()
      ? firebaseAuth.signInWithRedirect(auth, provider)
      : firebaseAuth.signInWithPopup(auth, provider);
  },

  reauthenticateInWithIDP: async provider => {
    const firebaseAuth = await Firebase.importAuth();
    const user = await Firebase.user();
    return Firebase.isRedirectFlow()
      ? firebaseAuth.reauthenticateWithRedirect(user, provider)
      : firebaseAuth.reauthenticateWithPopup(user, provider);
  },

  vippsReauthenticate: () => {
    if (Capacitor.isNativePlatform())
      return FirebaseAuthentication.signInWithOpenIdConnect({
        providerId: 'oidc.vipps',
        scopes: VIPPS_SCOPES.split(' ')
      });

    return Firebase.reauthenticateInWithIDP(vippsOpenIDConnectProvider);
  },

  vippsLogin: () => {
    if (Capacitor.isNativePlatform())
      return FirebaseAuthentication.signInWithOpenIdConnect({
        providerId: 'oidc.vipps',
        scopes: VIPPS_SCOPES.split(' ')
      });

    return Firebase.importAuth().then(firebaseAuth =>
      Firebase.signInWithIDP(firebaseAuth.getAuth(), vippsOpenIDConnectProvider).then(result => {
        const { accessToken } = firebaseAuth.OAuthProvider.credentialFromResult(result);
        NBXUsers.vippsUserInfo(accessToken)
          .then(async userInfo => {
            // eslint-disable-next-line no-console
            console.info('vippsLogin', 'userInfo', JSON.stringify(userInfo));
            await NBXStorage.setItem('vippsUserInfo', JSON.stringify(userInfo));
            // eslint-disable-next-line no-console
            console.info('vippsLogin', 'stored user info');
          })
          .catch(error => {
            console.error('vippsLogin', error);
          });

        return result;
      })
    );
  },

  googleReauthenticate: async () => {
    if (Capacitor.isNativePlatform()) {
      const result = await FirebaseAuthentication.signInWithGoogle();
      const firebaseAuth = await Firebase.importAuth();
      const credential = firebaseAuth.GoogleAuthProvider.credential(result.credential?.idToken);
      const user = await Firebase.user();
      return await firebaseAuth.reauthenticateWithCredential(user, credential);
    }

    return Firebase.reauthenticateInWithIDP(googleOpenIDConnectProvider);
  },

  googleLogin: async () => {
    if (Capacitor.isNativePlatform()) {
      console.log('firebase.service.googleLogin, FirebaseAuthentication.signInWithGoogle');
      const result = await FirebaseAuthentication.signInWithGoogle();
      const firebaseAuth = await Firebase.importAuth();
      console.log(
        'firebase.service.googleLogin, GoogleAuthProvider.credential',
        !!result,
        !!result.credential
      );
      const credential = firebaseAuth.GoogleAuthProvider.credential(result.credential?.idToken);
      const auth = await firebaseAuth.getAuth();
      console.log('firebase.service.googleLogin, firebaseAuth.signInWithCredential', !!credential);
      return await firebaseAuth.signInWithCredential(auth, credential);
    }

    return Firebase.importAuth().then(firebaseAuth =>
      Firebase.signInWithIDP(firebaseAuth.getAuth(), googleOpenIDConnectProvider)
    );
  },

  appleReauthenticate: async () => {
    if (Capacitor.isNativePlatform()) {
      const result = await FirebaseAuthentication.signInWithApple();
      const firebaseAuth = await Firebase.importAuth();
      const credential = firebaseAuth.AppleAuthProvider.credential(result.credential?.idToken);
      const user = await Firebase.user();
      return await firebaseAuth.reauthenticateWithCredential(user, credential);
    }

    return Firebase.reauthenticateInWithIDP(appleOpenIDConnectProvider);
  },

  appleLogin: async () => {
    if (Capacitor.isNativePlatform()) {
      const result = await FirebaseAuthentication.signInWithApple();
      const firebaseAuth = await Firebase.importAuth();
      const credential = firebaseAuth.AppleAuthProvider.credential(result.credential?.idToken);
      const auth = await firebaseAuth.getAuth();
      return await firebaseAuth.signInWithCredential(auth, credential);
    }

    return Firebase.importAuth().then(firebaseAuth =>
      Firebase.signInWithIDP(firebaseAuth.getAuth(), appleOpenIDConnectProvider)
    );
  },

  getRedirectResult: async () => {
    const firebaseAuth = await Firebase.importAuth();
    const auth = await firebaseAuth.getAuth();
    return firebaseAuth.getRedirectResult(auth);
  },

  isRedirectFlow: () => isMobileDevice(),

  // https://firebase.google.com/docs/reference/js/auth.md#signout
  logout: async () => {
    if (Capacitor.isNativePlatform()) {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native signout()');
        return FirebaseAuthentication.signOut();
      }
    }

    return Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.signOut(auth);
    });
  },

  // https://firebase.google.com/docs/reference/js/auth.md#reload
  reloadUser: () =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.reload(user);
    }),

  reauthenticate: (email, password) => {
    // We rely on authservice to maintain sessions and so don't maintain a Firebase session
    // so to "reauthenticate" we just login again
    return Firebase.login(email, password);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#sendpasswordresetemail
  resetPassword: email =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.sendPasswordResetEmail(auth, email);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#sendemailverification
  sendEmailVerification: settings =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.sendEmailVerification(user, settings);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#checkactioncode
  checkActionCode: actionCode =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.checkActionCode(auth, actionCode);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#applyactioncode
  applyActionCode: actionCode =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.applyActionCode(auth, actionCode);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#confirmpasswordreset
  confirmPasswordReset: (actionCode, password) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.confirmPasswordReset(auth, actionCode, password);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#updateemail
  changeEmail: newEmail =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.updateEmail(user, newEmail);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#updatepassword
  changePassword: password =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.updatePassword(user, password);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#deleteuser
  deleteUser: () =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.deleteUser(user);
    }),

  createRecaptchaVerifier: ({ containerId, options }) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return new firebaseAuth.RecaptchaVerifier(
        auth,
        containerId,
        window.PLATFORM === 'ios' ? { ...options, 'auth-type': 'ios' } : options
      );
    }),

  multiFactorUser: () =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.multiFactor(user);
    }),

  multiFactorResolver: error =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.getMultiFactorResolver(auth, error);
    }),

  verifyPhoneNumber: ({ phoneInfoOptions, recaptchaVerifier }) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      console.log(
        'firebase.service.verifyPhoneNumber, create PhoneAuthProvider, phoneInfoOptions = ',
        phoneInfoOptions,
        'recaptchaVerifier = ',
        recaptchaVerifier
      );
      const phoneAuthProvider = new firebaseAuth.PhoneAuthProvider(auth);
      console.log(
        'firebase.service.verifyPhoneNumber, preparing sending sms, renderring recaptchaVerifier, phoneAuthProvider = ',
        phoneAuthProvider
      );
      return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    }),

  multiFactorAssertion: ({ verificationId, verificationCode }) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const credential = firebaseAuth.PhoneAuthProvider.credential(verificationId, verificationCode);
      console.log('firebase.service.multiFactorAssertion, verify sms', !!credential);
      return firebaseAuth.PhoneMultiFactorGenerator.assertion(credential);
    }),

  enrollMultiFactorUser: ({ verificationId, verificationCode }) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      console.log('firebase.service.enrollMultiFactorUser, multiFactorAssertion');
      const multiFactorAssertion = await Firebase.multiFactorAssertion({ verificationId, verificationCode });
      console.log('firebase.service.enrollMultiFactorUser, enroll user', !!multiFactorAssertion);
      return firebaseAuth.multiFactor(user).enroll(multiFactorAssertion);
    })
};

export default Firebase;
